//@author: devin
import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";

export class ResponseMessage {
    message?: string;
    messageCode?: string;
    bulkInsertResponse?: BulkInsertResponse;
}

export class BulkInsertResponse {
    outputs?: { [key: string]: string }[];
}

export class DataAccess {
    public static DataAccess_HEADERS: any = {
        contentType: {
            xml: { "Content-Type": "text/xml;charset=utf-8", Accept: "text/xml" },
            json: { "Content-Type": "application/json" },
        },
    };

    public static DataAccess_RETRY: any = {
        responseCodes: { 429: true, 0: true },
        maxRetry: 5,
        waitMs: 1000,
    };

    //Note: maintenancePage.js overrides XMLHttpRequest.prototype.open to always send async, which is required for this to work
    private static httpClient: HttpClient = new HttpClient(
        new HttpXhrBackend({
            build: () => {
                return new XMLHttpRequest();
            },
        }),
    );

    public static get<T = any>(
        serviceUrl: string,
        isHttps?: boolean,
        customHeaders?: any,
        responseType?: "arraybuffer" | "blob" | "text" | "json",
        observe?: "body" | "events" | "response",
    ): Promise<T> {
        return DataAccess.http("get", serviceUrl, null, isHttps, customHeaders, responseType, null, observe);
    }

    public static post<T = any>(
        serviceUrl: string,
        data?: any,
        customHeaders?: any,
        responseType?: "arraybuffer" | "blob" | "text" | "json",
    ): Promise<T> {
        return DataAccess.http("post", serviceUrl, data, null, customHeaders, responseType);
    }

    public static put<T = any>(
        serviceUrl: string,
        data?: any,
        customHeaders?: any,
        responseType?: "arraybuffer" | "blob" | "text" | "json",
    ): Promise<T> {
        return DataAccess.http("put", serviceUrl, data, null, customHeaders, responseType);
    }

    public static delete<T = any>(serviceUrl: string, data?: any, customHeaders?: any): Promise<T> {
        return DataAccess.http("delete", serviceUrl, data, null, customHeaders);
    }

    public static postFile(serviceUrl: string, inputFile: any) {
        var formData = new FormData();
        formData.append("file", inputFile);
        return DataAccess.http("post", serviceUrl, formData, null, {});
    }

    public static composeParam(name: string, value: string | number, noAmp?: boolean) {
        if (S25Util.isUndefined(name) || S25Util.isUndefined(value)) {
            return "";
        }
        return (noAmp ? "" : "&") + name + "=" + value;
    }

    public static composeParamUrlEncode(name: string, value: string | number, noAmp?: boolean) {
        if (S25Util.isUndefined(name) || S25Util.isUndefined(value)) {
            return "";
        }
        return DataAccess.composeParam(encodeURIComponent(name), encodeURIComponent(value), noAmp);
    }

    public static injectCaller(url: string, caller?: string): string {
        caller = caller || "pro";

        if (!caller || !url) {
            return url;
        }

        // contains - prepend
        if (S25Util.contains(url, "caller=")) {
            return url.replace("caller=", "caller=" + caller + "-");
        }

        // incomplete URL - replace "?" with new param; s25ModalSubscribeController
        // http://bugs.collegenet.com/browse/ANG-1474
        if (url.endsWith("=") && S25Util.contains(url, "?")) {
            return url.replace("?", "?" + DataAccess.composeParam("caller", caller, true) + "&");
        }

        // append new param
        return url + (S25Util.contains(url, "?") ? "&" : "?") + DataAccess.composeParam("caller", caller, true);
    }

    public static composeUrl(serviceUrl: string, isHttps?: boolean): string {
        if (serviceUrl && serviceUrl.startsWith("http")) {
            return serviceUrl;
        }
        if (!S25Const.webservicesBaseUrl) console.error("baseUrl not set", serviceUrl); //helpful when test is making a real call
        let url: string = S25Const.webservicesBaseUrl.replace("http://", "http" + (isHttps ? "s" : "") + "://");
        url += serviceUrl;
        url = DataAccess.injectCaller(url, "pro");
        return url;
    }

    public static getUrl(serviceUrl: string) {
        return DataAccess.composeUrl(serviceUrl);
    }

    public static massageData(data: any, isXml?: boolean): any {
        //add r25 namespace to all xml requests, if not already present
        if (data && data.indexOf && data.replace && data.indexOf("xmlns:r25") === -1 && data.indexOf("r25:") > -1) {
            data = data.replace(/<(r25:.*?)>/, '<$1 xmlns:r25="http://www.collegenet.com/r25">');
        }

        //for debugging purposes, " is always preferred instead of " for XML attribute declarations (added on request from Nathan)
        if (data && data.replace && isXml) {
            data = S25Util.removeInvalidXmlCharacters(data);
            data = data.replace(/"/g, '"');
        }

        if (typeof data === "object" && data !== null) {
            S25Util.propertyDeleteStartsWith(data, "$");
            S25Util.removeInvalidXmlCharacters(data);

            // serialize dates before passing to WS
            S25Util.dfs(data, (node, parent, childKey) => {
                if (S25Util.date.isDate(node)) {
                    parent[childKey] = S25Util.date.toS25ISODateTimeStr(node);
                }
            });
        }

        return data;
    }

    public static isXmlRequest(url: string): boolean {
        return (
            url.indexOf(".xml?") !== -1 ||
            url.indexOf(".xml", url.length - 4) !== -1 ||
            url.indexOf(".copyto") !== -1 ||
            url.indexOf(".mail") !== -1 ||
            url.indexOf(".run") !== -1 ||
            url.indexOf("s25_exec") !== -1
        );
    }

    public static isJsonRequest(url: string): boolean {
        return url.indexOf(".json") > -1 || url.indexOf(".generate") !== -1;
    }

    public static http(
        method: string,
        serviceUrl: string,
        data?: any,
        isHttps?: boolean,
        customHeaders?: any,
        responseType?: "arraybuffer" | "blob" | "text" | "json",
        curRetry?: number,
        observe?: "body" | "events" | "response",
    ) {
        let url: string = DataAccess.composeUrl(serviceUrl, isHttps);
        let massagedData = DataAccess.massageData(data, DataAccess.isXmlRequest(serviceUrl));
        curRetry = S25Util.coalesce(curRetry, 0);

        let headers: any =
            customHeaders ||
            (DataAccess.isXmlRequest(serviceUrl)
                ? DataAccess.DataAccess_HEADERS.contentType.xml
                : DataAccess.isJsonRequest(serviceUrl)
                  ? DataAccess.DataAccess_HEADERS.contentType.json
                  : undefined);

        if (DataAccess.isXmlRequest(serviceUrl)) {
            responseType = responseType || "text";
        }

        return S25Const.getPersistentSessionIdParam().then(function () {
            if (S25Const.persistentSessionIdParam) {
                headers = headers || {};
                headers["X-SERIES25-SESSION_ID"] = S25Const.persistentSessionIdParam;
            }

            return DataAccess.httpClient
                .request(method, url, {
                    body: massagedData || "", //note: data of "" needed for angular $http to NOT strip content-type header...
                    headers: headers,
                    responseType: responseType,
                    withCredentials: url.indexOf("lynx") > -1,
                    observe,
                })
                .toPromise()
                .then(
                    function (resp: any) {
                        return resp;
                    },
                    function (err: any) {
                        //callers expect data property to have error info
                        if (err && err.error && !err.data) {
                            err.data = err.error;
                        }

                        // non-configured response codes - reject as-is
                        if (!DataAccess.DataAccess_RETRY.responseCodes[err.status]) {
                            throw err;
                        } else if (curRetry > DataAccess.DataAccess_RETRY.maxRetry) {
                            console.log(method + ": " + url);
                            console.log(
                                "status=" +
                                    err.status +
                                    ", rejecting" +
                                    ", retry " +
                                    curRetry +
                                    " of " +
                                    DataAccess.DataAccess_RETRY.maxRetry,
                            );
                            throw err;
                        } else {
                            return jSith.timeout(
                                () =>
                                    DataAccess.http(
                                        method,
                                        serviceUrl,
                                        data,
                                        isHttps,
                                        customHeaders,
                                        responseType,
                                        curRetry + 1,
                                    ),
                                DataAccess.DataAccess_RETRY.waitMs,
                            );
                        }
                    },
                );
        });
    }
}
